<template>
  <v-app id="inspire">
      <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <update-details-form :is-show="isShowUpdateDetails" :details="updateDetails" v-on:update="update" v-on:close-dialog="closeDialog"/>
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Update Configuration</h1>
              <v-card color="grey lighten-1" class="mb-12" height="auto">
                  <v-card-text>
                    <v-autocomplete
                    clearable chips
                    :items="updatableTableList"
                    item-text="description"
                    item-value="id"
                    label="Select a topic"
                    v-model="selectedUpdatableTable"
                    return-object
                    @change="queryData(false)"
                    data-cy="updatableList-field">
                    </v-autocomplete>
                  </v-card-text>
              </v-card>
        </v-container>

        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="tableList"
              :page.sync="page"
              :items-per-page="rowsPerPage"
              :loading="loading"
              hide-default-footer
              class="elevation-2 mt-4 px-4"
              data-cy="data-table"
            >
              <template v-slot:[`item.controls`]="{ item }">
                  <v-icon
                    medium
                    color="green"
                    class="mr-2"
                    @click="view(item)"
                  >
                    mdi-magnify-plus
                  </v-icon>
                  <v-icon
                    medium
                    color="green"
                    class="mr-2"
                    @click="showForm('UPDATE', item)"
                  >
                    mdi-pencil
                  </v-icon>
              </template>
              <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                      Found no results.
                  </v-alert>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="numberOfPages"
              ></v-pagination>
            </div>
          </div>
        </template>
      </div>
  </v-app>
</template>

<script>
  import CircuitBreakerAPI from '@/api/CircuitBreakerAPI.js'
  import Loading from '@/views/components/Loading.vue'
  import DetailsForm from '@/views/components/DetailsForm.vue'
  import UpdateDetailsForm from '@/views/components/UpdateDetailsForm.vue'
  import {
      mapGetters
  } from 'vuex';

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  export default {
      name: 'UpdateConfig',
      components: {
          Loading,
          DetailsForm,
          UpdateDetailsForm
      },
      watch: {
        page: {
            handler() {
                this.queryData();
            },
            deep: true
        }
      },
      computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'roles',
            'authorities'
        ]),
      },
      data() {
        return {
            headers: [],
            tableList: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            formType: 'CREATE',
            selectedRow: {},
            numberOfPages: 0,
            page: 1,
            rowsPerPage: 5,
            details: {
                title: '',
                item: {}
            },
            updateDetails: {
              title: '',
              item: {}
            },
            query: {},
            isShowDetails: false,
            isShowUpdateDetails: false,
            showFormDialog: false,
            isHtml: false,
            updatableTableList: [],
            selectedUpdatableTable: undefined
        }
      },
      async mounted() {
        this.setHeaders()
        //call table list here
        this.queryTableList()
      },
      methods: {
        async queryTableList() {
          console.log("query table list")
          try {
            const response = await CircuitBreakerAPI.getAllUpdatableTables(0, 200, this.query)
            if (!response || response.error) {
                //error getting data
                console.log(`${response.error}`)
            } else {
              this.updatableTableList = response.updatableTables
              this.numberOfPages = response.pageSize
            }
            this.loading = false
          } catch (e) {
              console.log(e)
              this.loading = false
          }
        },
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.page = 1
            }
            let pageNumber = this.page - 1;
            try {
                const response = await CircuitBreakerAPI.getAllTableValues(pageNumber, this.rowsPerPage, this.selectedUpdatableTable.tableName)
                if (!response || response.error) {
                    //error getting data
                    console.log(`${response.error}`)
                } else {
                  this.tableList = response.storeThresholds
                  this.numberOfPages = response.pageSize
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        showForm(what, item = {}) {
            // this.formType = what
            // this.selectedRow = item
            // this.showFormDialog = true
            this.updateDetails.title = item.id
            this.updateDetails.item = item
            this.isShowUpdateDetails = true
        },
        closeDialog(type) {
          console.log(type)
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                //this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            } else if (type === 'update-details') {
                this.updateDetails.title = ''
                this.updateDetails.item = {}
                this.isShowUpdateDetails = false
            }
        },
        update(value) {
          console.log(value)
          this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
              this.loadingMessage = `Updating ${this.selectedUpdatableTable.tableName} ID: <strong><i>${value.id}</i></strong>`
              setTimeout(async () => {
                  value.tableName = this.selectedUpdatableTable.tableName
                  const updateResponse = await CircuitBreakerAPI.UpdateTableValue(value)
                  this.loadingFinished = true
                  if (!updateResponse || updateResponse.error) {
                      this.isHtml = false
                      this.loadingMessage = updateResponse.error
                  } else {
                      this.loadingMessage = `Successfully updated ${this.selectedUpdatableTable.tableName} ID: <strong><i>${value.id}</i></strong>`
                      this.closeDialog("update-details");
                      this.queryData(false)
                  }
              }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        save(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                // if (value.formType === 'CREATED') {
                //     this.loadingMessage = `Creating new Wallet System <strong><i>${value.code}</i></strong>`
                //     setTimeout(async () => {
                //         const createResponse = await API.createWalletSystem(value)
                //         console.log(createResponse)
                //         this.loadingFinished = true
                //         if (!createResponse || createResponse.error) {
                //             this.isHtml = false
                //             this.loadingMessage = createResponse.error
                //         } else {
                //             this.loadingMessage = `Successfully created Wallet System <strong><i>${value.code}</i></strong>`
                //             this.showFormDialog = false
                //             this.$refs.WalletSystemForm.resetForm()
                //         }
                //     }, 2000);

                // } else if (value.formType === 'UPDATE') {
                //     this.loadingMessage = `Updating new Wallet System <strong><i>${value.code}</i></strong>`
                //     setTimeout(async () => {
                //         const updateResponse = await API.updateWalletSystem(value)
                //         this.loadingFinished = true
                //         if (!updateResponse || updateResponse.error) {
                //             this.isHtml = false
                //             this.loadingMessage = updateResponse.error
                //         } else {
                //             this.loadingMessage = `Successfully updated Wallet System <strong><i>${value.code}</i></strong>`
                //             this.showFormDialog = false
                //             this.$refs.WalletSystemForm.resetForm()
                //         }
                //     }, 2000);
                // } else {
                //     this.loadingMessage = "Invalid command"
                //     this.loadingFinished = true
                // }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        view(item) {
            this.details.title = item.storeName
            this.details.item = item
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        },
        setHeaders() {
            var headers = [{
                    text: 'id',
                    value: 'id',
                    align: 'center',
                },
                {
                    text: 'Store No',
                    value: 'storeNo',
                    align: 'center',
                },
                {
                    text: 'Store Name',
                    value: 'storeName',
                    align: 'center',
                },
                {
                    text: 'Is Enabled',
                    value: 'enabled',
                    align: 'center',
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    align: 'center',
                    sortable: false
                }
            ]
            this.headers = headers
        },
      },
  }
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
