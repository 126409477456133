<template>
<v-container row justify-center>
    <v-dialog max-width="600px" persistent v-model="isShowDetails">
        <v-card>
            <v-card-title class="headline">
                {{details.title}}
            </v-card-title>
            <v-card-text>
                <v-row v-for="(field, index) of getFields()" :key="index">
                    <v-col cols="12" v-if="isJSON(field[1]) && setupField(field)">
                        <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" :readonly="field[0] == 'id'" v-model="updatedData[field[0]]" :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-textarea>
                    </v-col>
                    <v-col cols="12" v-else-if="setupField(field)">
                        <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" :readonly="field[0] == 'id'" v-model="updatedData[field[0]]" :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="close" data-cy="close-button">
                    Close
                </v-btn>
                <v-btn color="green darken-1" text @click="update" data-cy="update-button">
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        details: Object
    },
    watch: {
        isShow: {
            handler(value) {
                this.isShowDetails = value
            }
        },
        deep: true,
    },
    data() {
        return {
            isShowDetails: this.isShow ?? false,
            updatedData: {}
        }
    },
    methods: {
        getFields() {
            const arrOfArrays = Object.entries(this.details.item);

            const sorted = arrOfArrays.sort((a, b) => {
            return a[0].localeCompare(b[0]);
            });
            return sorted;
        },
        setupField(field) {
          this.updatedData[field[0]] = this.stringify(field[1]);
          return true
        },
        convertCamelCase(string) {
            return string.replace(/([a-z])([A-Z])/g, '$1 $2');
        },
        close() {
            this.isShowDetails = false
            this.$emit('close-dialog', 'update-details')
        },
        update() {
            this.$emit('update', this.updatedData)
        },
        isJSON(value) {
          return Object.prototype.toString.call(value) === '[object Array]' || Object.prototype.toString.call(value) === '[object Object]'
        },
        stringify(value) {
          if(Object.prototype.toString.call(value) === '[object Array]') {
            var items = []
            for(var index in value) {
              if(value[index].role) {
                items.push(value[index].role)
              }
            }
            if(items.length > 0) {
              return items.toString()
            }
            return JSON.stringify(value)
          } else if(Object.prototype.toString.call(value) === '[object Object]') {
            if(value.role) {
              return value.role
            }
            return JSON.stringify(value)
          } else {
            return value.toString()
          }
        }
    }
}
</script>
